import React from "react";
import Button from "@material-ui/core/Button";
import { Modal } from "components";
import { Typography, Box } from "@material-ui/core";

const InfoModal = (props) => {
  const { modalOpen, onClose, title, children } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal
      title={title}
      open={modalOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      actions={
        <Button autoFocus onClick={handleClose} color="primary">
          OK
        </Button>
      }
    >
      {children}
      {/*<>
        {errors && errors.map((e, index) => (
          <Typography key={index} component="div" style={{ display: 'flex' }} gutterBottom>
            <Box fontWeight="fontWeightBold" style={{paddingRight: '2em'}}>
              {`${index + 1}.`}
            </Box>
            <Box>
              {e}
            </Box>
          </Typography>
        ))}
        </>*/}
    </Modal>
  );
};

export default InfoModal;
