import React, { useRef, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useRecoilState, useRecoilValue } from "recoil";
import useSWR from "swr";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  IconButton,
  Tooltip,
  Button,
  Box,
  Typography,
  Grid
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import TelegramIcon from "@material-ui/icons/Telegram";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSnackbar } from "notistack";
import { snacks } from "helpers";
import { vendorSearch, vendors } from "store";

import { SearchInput, LinearProgress } from "components";

import { isLoading, vendorsSelected as vendsSelected } from "store";
import { VENDORS_END, ATTENDANCE_END } from "config";
import { getData, postData } from "fetchers";

import FileDownload from "js-file-download";

import { VendorListContext } from "../../VendorList";

import { getDropCollsPw } from "config";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    minHeight: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  overviewButton: {
    marginRight: theme.spacing(1),
  },
  closedInfoText: {
    marginRight: theme.spacing(4),
    textAlign: "center",
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  refreshButton: {
    marginRight: theme.spacing(1),
  },
  downloadFilterButton: {
    marginRight: theme.spacing(1),
  },
  dropButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  rightControlWrapper: {
    marginLeft: "auto",
    textAlign: "right"
  },
  notifyButton: {
    marginRight: theme.spacing(1),
  },
}));

const VendorsToolbar = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const { data, mutate } = useSWR(VENDORS_END);
  const { data: attData, mutate: attMutate, error: attError } = useSWR(
    ATTENDANCE_END
  );

  const { enqueueSnackbar } = useSnackbar();

  const [appIsLoading, setAppIsLoading] = useRecoilState(isLoading);
  const [searchVendor, setSearchVendor] = useRecoilState(vendorSearch);
  const vendorsSelected = useRecoilValue(vendsSelected);

  const [countDown, setCountDown] = useState(0);

  const [refreshInterval, setRefreshInterval] = useState(null);

  const ctx = useContext(VendorListContext);

  /*useEffect(() => {
    attMutate();
  }, []);*/

  useEffect(() => {
    if (!attData && !attError) {
      setAppIsLoading(true);
    } else {
      setAppIsLoading(false);
      if (attError) {
        //const { status } = attError.response;
        enqueueSnackbar(
          "Informácie o Dochádzke sa nepodarilo získať.",
          snacks.ERROR
        );
        /*if (error.response.status !== 401) {
          enqueueSnackbar(
            "Informácie o Dohodároch sa nepodarilo získať.",
            snacks.ERROR
          );
        }*/
      }
    }
  }, [attData, attError]);

  useEffect(() => {
    return () => {
      clearInterval(refreshInterval);
    };
  }, [refreshInterval]);

  const handleOverview = async () => {
    try {
      setAppIsLoading(true);
      const res = await getData(
        ATTENDANCE_END + "/export",
        {
          "csobl-close": "false",
        },
        null,
        "blob"
      );
      FileDownload(res, "Dochadzka_nahlad.xlsx");
      setAppIsLoading(false);
    } catch (err) {
      enqueueSnackbar(`Pri generovaní náhľadu nastala chyba.`, snacks.SUCCESS);
      setAppIsLoading(false);
    }
  };

  const handleExport = async () => {
    //const { closed: attClosed } = attData;
    if (!attData?.closed) {
      ctx.setExportConfirmationOpen(true);
    } else {
      try {
        setAppIsLoading(true);
        const res = await getData(
          ATTENDANCE_END + "/export",
          {
            "csobl-close": "true",
          },
          null,
          "blob"
        );
        FileDownload(res, "Dochadzka.xlsx");
        setAppIsLoading(false);
      } catch (err) {
        enqueueSnackbar(`Pri exporte dochádzky nastala chyba.`, snacks.SUCCESS);
        setAppIsLoading(false);
      }
    }
  };

  const handleDownloadFilter = async () => {
    try {
      setAppIsLoading(true);
      const res = await getData(
        VENDORS_END + "/filter",
        {
          targets: vendorsSelected,
        },
        null,
        "blob"
      );
      FileDownload(res, "Zobrazenie.xlsx");
      setAppIsLoading(false);
    } catch (err) {
      enqueueSnackbar(
        `Pri generovaní zobrazenia nastala chyba.`,
        snacks.SUCCESS
      );
      setAppIsLoading(false);
    }
  };

  /*const handleDropColls = async () => {
    try {
      const rest = await postData("/api/service/drop", {
        password: getDropCollsPw(),
      });
      enqueueSnackbar("databaza bola zmazana", snacks.SUCCESS);
    } catch (e) {
      enqueueSnackbar("databazu sa nepodarilo zmazat", snacks.ERROR);
    }
  };*/

  const handleRefresh = async () => {
    if (countDown === 0) {
      setAppIsLoading(true);
      mutate(async () => {
        const refreshedData = await getData(VENDORS_END, null, {
          refresh: true,
        });
        setAppIsLoading(false);
        return refreshedData;
      });
      setCountDown(10);
      const interval = setInterval(() => {
        setCountDown((prevCountDown) => {
          if (prevCountDown === 1) clearInterval(interval);
          return prevCountDown - 1;
        });
      }, 1000);
      setRefreshInterval(interval);
    } else {
      enqueueSnackbar(
        `Dáta môžu byť obnovené najskôr o ${countDown} sekúnd.`,
        snacks.ERROR
      );
    }
  };

  const handleNotify = () => {
    ctx.setSendNotifConfirmationOpen(true);
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchVendor(value);
  };

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {appIsLoading ? <LinearProgress value={null} /> : null}
        {!attData?.closed ? (
          <Button className={classes.overviewButton} onClick={handleOverview}>
            Náhľad
          </Button>
        ) : (
            <Typography component="div" className={classes.closedInfoText}>
              <>
                <span>Dochádzka bola uzatvorená:</span>
                <br />
                <span className={classes.bold}>
                  {new Date(attData.updatedAt).toLocaleDateString(
                    "sk-SK",
                    dateOptions
                  )}
                </span>
              </>
            </Typography>
          )}
        <Button
          className={classes.exportButton}
          color="primary"
          variant="contained"
          onClick={handleExport}
        >
          Export
        </Button>
      </div>
      <div className={classes.row}>
        <Grid
          container
        >
          <Grid item xs={12} sm={4}>
            <SearchInput
              className={classes.searchInput}
              placeholder="Vyhľadať dohodára"
              onChange={handleSearch}
              disabled={appIsLoading}
              value={searchVendor}
            />
          </Grid>
          <Grid item xs={10} sm={7}>
            <Tooltip title="Obnoviť">
              <IconButton
                aria-label="refresh"
                className={classes.refreshButton}
                onClick={handleRefresh}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Stiahnuť zobrazenie">
              <IconButton
                aria-label="downloadFilter"
                className={classes.downloadFilterButton}
                onClick={handleDownloadFilter}
              //disabled={!vendorsSelected.length}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>

            {/*<IconButton
              aria-label="dropColls"
              className={classes.dropButton}
              onClick={handleDropColls}
            >
              <DeleteForeverIcon />
            </IconButton>*/}
          </Grid>
          <Grid item xs={2} sm={12}>
            <div className={classes.rightControlWrapper}>
              {!attData?.closed ? (
                <Tooltip title="Odoslať notifikáciu">
                  <span>
                    <IconButton
                      aria-label="refresh"
                      className={classes.notifyButton}
                      onClick={handleNotify}
                      disabled={vendorsSelected.length ? false : true}
                    >
                      <TelegramIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

VendorsToolbar.propTypes = {
  className: PropTypes.string,
};

export default VendorsToolbar;
