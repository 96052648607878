export const getBaseAPIUrl = () => {
  return process.env.REACT_APP_BASE_API_URL
}

export const AUTH_END = "/api/auth"

export const SIGN_IN_END = "/api/auth/signin"
export const LOST_PASS_END = "/api/auth/lost-password"
export const CHANGE_PASS_END = "/api/auth/change-password"


export const ATTENDANCE_END = "/api/attendance"
export const VENDORS_END = "/api/vendors"
export const RECORDS_END = "/api/records"

export const HOLIDAYS_END = "/api/service/holidays"






