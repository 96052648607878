import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

import { Sidebar, Topbar, Footer } from "./components";
import { HelpModal, ConfirmationModal } from "components/Modal";

import { help, confirmationModal } from "store";
import { loggedUser } from "store";

// TODO: temporary commented
//import app from 'fbase';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: "100%",
  },
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openHelp, setOpenHelp] = useRecoilState(help);
  const [user, setUser] = useRecoilState(loggedUser);

  const [openSignOut, setOpenSignOut] = useRecoilState(confirmationModal);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleSignOut = () => {
    //resetUser();
    //resetCustomTkn()
    // TOTO: temporary commnted
    //app.auth().signOut()
    setUser(null);
  };

  const handleSignOutCancel = () => {
    setOpenSignOut(false);
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <ConfirmationModal
        modalOpen={openSignOut}
        onClose={() => handleSignOutCancel()}
        onNegative={() => handleSignOutCancel()}
        onPositive={() => handleSignOut()}
        title="Odhlásenie"
        message="Naozaj sa chcete odhlásiť?"
      />
      {/*<HelpModal
        modalOpen={openHelp}
        onClose={() => setOpenHelp(false)}
        instructions={helpText}
      />*/}
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
      />
      <main className={classes.content}>
        {children}
        {/*<Footer />*/}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
