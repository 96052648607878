import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import RouteWithLayout from "./RouteWithLayout";

import { useRecoilValue } from "recoil";
import { loggedUser } from "store";

import { useAuth, useGetBearer } from "Authenticator";

const GuardedRouteWithLayout = ({ component: Component, ...rest }) => {
  const displayName = Component.displayName;

  const isSignOrLost =
    displayName &&
    (displayName.includes("SignIn") || displayName.includes("LostPassword"));

  const user = useRecoilValue(loggedUser);

  return (
    // TODO: temporary commented
    <RouteWithLayout
      {...rest}
      component={(props) =>
        user && user.token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/sign-in" />
        )
      }
    />
  );
};

GuardedRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default GuardedRouteWithLayout;
