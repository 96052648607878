import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

import { skSK } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
}, skSK);

export default theme;
