import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useRecoilValue, useRecoilState } from "recoil";
import { Redirect, withRouter } from "react-router-dom";

import { useSnackbar } from "notistack";
import { snacks } from "helpers";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import { isLoading, loggedUser } from "store";
import { postData } from "fetchers";
import { CHANGE_PASS_END } from "config";
import { Grid, Button, TextField, CircularProgress } from "@material-ui/core";
import { AUTH_END } from "config";
import { getData } from "fetchers";

//Regular Expressions.
const regexList = [];
regexList.push(/[A-Z]/);
regexList.push(/[a-z]/);
regexList.push(/[0-9]/);
//regexList.push(/[!@#$%^&*]/);

validate.validators.passComplexity = function (
  value,
  options,
  key,
  attributes
) {
  const valid = regexList.reduce((acc, cur) => {
    return cur.test(value) && acc;
  }, true);
  return valid ? null : options.message;
};

const schema = {
  password1: {
    presence: { allowEmpty: false, message: "^Heslo nemôže byť prázdne." },
    passComplexity: {
      message: "^Musí obsahovať minimálne 1 malé, veľké písmeno a číslo.",
    },
    length: {
      tooShort: "^Minimálna dĺžka hesla je 6 znakov.",
      tooLong: "^Maximálna dĺžka hesla je 128 znakov.",
      minimum: 6,
      maximum: 128,
    },
  },
  password2: {
    presence: { allowEmpty: false, message: "^Heslo nemôže byť prázdny." },
    length: {
      message: "^Minimálna dĺžka hesla je 6 znakov.",
      minimum: 6,
    },
    length: {
      tooShort: "^Minimálna dĺžka hesla je 6 znakov.",
      tooLong: "^Maximálna dĺžka hesla je 128 znakov.",
      minimum: 6,
      maximum: 128,
    },
    equality: {
      attribute: "password1",
      message: "^Heslá sa musia zhodovať.",
      comparator: (v1, v2) => {
        return v1 === v2;
      },
    },
  },
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.default,
      height: "calc(100vh - 64px)",
    },
    grid: {
      height: "100%",
    },
    contentContainer: {},
    content: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentHeader: {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(5),
      paddingBototm: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    contentBody: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      /*[theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },*/
    },
    form: {
      flexBasis: 500,
      /*paddingLeft: 100,
      paddingRight: 100,
      paddingBottom: 125,
      flexBasis: 700,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },*/
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    submitButton: {
      margin: theme.spacing(2, 0),
    },
    centered: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "32px",
    },
    margin: {
      margin: "32px 32px",
    },
  };
});

const ChangePassword = (props) => {
  const { history } = props;

  const classes = useStyles();

  const notificationId = props.match.params.id;

  const [notifValid, setNotifValid] = useState(true);

  const [appIsLoading, setAppIsLoading] = useRecoilState(isLoading);
  const { enqueueSnackbar } = useSnackbar();

  const user = useRecoilValue(loggedUser);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    (async () => {
      try {
        setAppIsLoading(true);

        const notifValid = await getData(`${AUTH_END}/${notificationId}`);
        setAppIsLoading(false);
      } catch (err) {
        setAppIsLoading(false);

        const { status } = err.response;
        if (status === 400) {
          setNotifValid(false);
        } else {
          enqueueSnackbar(err.message, snacks.ERROR);
          setAppIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setAppIsLoading(true);
      const res = await postData(CHANGE_PASS_END, {
        notificationId,
        password: formState.values.password1,
      });
      enqueueSnackbar(
        "Heslo bolo úspešne zmenené, môžete sa prihlásiť.",
        snacks.SUCCESS
      );
      history.push("/");
    } catch (err) {
      const { status } = err?.response;
      if (status === 404) {
        enqueueSnackbar("Používateľ neexistuje.", snacks.ERROR);
      } else {
        enqueueSnackbar("Zmena hesla bola neúspešná.", snacks.ERROR);
      }
    } finally {
      setAppIsLoading(false);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return user && user.token ? (
    <Redirect to="/" />
  ) : notificationId && notifValid ? (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              {/*<IconButton onClick={handleBack}>
              <ArrowBackIcon />
  </IconButton>*/}
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  className={classes.textField}
                  error={hasError("password1")}
                  fullWidth
                  helperText={
                    hasError("password1") ? formState.errors.password1[0] : null
                  }
                  label="Zadajte nové heslo"
                  name="password1"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password1 || ""}
                  variant="outlined"
                  disabled={appIsLoading}
                />
                <TextField
                  className={classes.textField}
                  error={hasError("password2")}
                  fullWidth
                  helperText={
                    hasError("password2") ? formState.errors.password2[0] : null
                  }
                  label="Zopakujte nové heslo"
                  name="password2"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password2 || ""}
                  variant="outlined"
                  disabled={appIsLoading}
                />
                <Button
                  className={classes.submitButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {!appIsLoading ? (
                    "Odoslať"
                  ) : (
                    <CircularProgress color="secondary" size={26} />
                  )}
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.centered}>
        <h2 className={classes.margin}>Ľutujeme,</h2>
        <p>platnosť URL adresy vypršala.</p>
      </div>
    </div>
  );
};

ChangePassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ChangePassword);
