import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import FullCalendar, { DateComponent } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import styled from "styled-components";
import skLocale from "@fullcalendar/core/locales/sk";
//import {useIsMount} from "utils";

// must manually import the stylesheets for each plugin
//import "@fullcalendar/core/main.css";
//import "@fullcalendar/daygrid/main.css";
//import "@fullcalendar/timegrid/main.css";

const CalendarWrapper = styled.div`
  margin: 0 auto;
  max-width: 900px;
  height: 100vh;
`;

const DayCellTitle = styled.div`
  font-size: 14px;
  padding: 8px;
  text-align: center;
`;

const renderTile = ({ event, editable }) => {
  /*const eventComponentRef = useRef();

  useEffect(() => {
    new Draggable(eventComponentRef.current)
  }, [])*/

  const EventTile = (
    <div
      className="eventik"
      style={
        editable
          ? {
              color: "#FFFFFF",
              backgroundColor: "#00adee",
              width: "100%",
              padding: "4px 4px 4px 4px",
            }
          : {
              color: "#000000",
              backgroundColor: "#F1F1F1",
              width: "100%",
              padding: "4px 4px 4px 8px",
            }
      }
    >
      {event.title}
    </div>
  );

  //new Draggable(EventTile);

  return EventTile;
};

const resolveDayCellClasses = (props) => {
  const { date, disabledDays } = props;
  if (disabledDays.map((d) => d.day).includes(date.getDate())) {
    return "fc-day-disabled";
  } else {
    return "";
  }
};

const fillDayCellContent = (props) => {
  const { dayNumberText, date, disabledDays } = props;
  const dayIndex = disabledDays.map((d) => d.day).indexOf(date.getDate());
  if (dayIndex > -1) {
    return (
      <>
        <div style={{textAlign: 'right'}}>{dayNumberText}</div>
        <DayCellTitle>{disabledDays[dayIndex].title}</DayCellTitle>
      </>
    );
  } else {
    return dayNumberText;
  }
};


const Calendar = (props) => {
  const { data, initialDate, editable, disabledDays, onChange } = props;

  /*const [initDate, setInitDate] = useState(initialDate);

  useEffect(() => {
    setInitDate(initialDate);
  }, [initialDate]);*/

  //useEffect(() => [initialDate])

  /*let startTime = new Date();
  startTime.setHours(startTime.getHours() - 2);
  let endTime = startTime;
  endTime.setHours(endTime.getHours() + 1);

  const d1s = new Date();
  d1s.setDate(d1s.getDate() + 1);
  const d1e = new Date();
  d1e.setDate(d1e.getDate() + 1);

  const d2s = new Date();
  d2s.setDate(d2s.getDate() -3);
  const d2e = new Date();
  d2e.setDate(d2e.getDate() - 3);


  const d3s = new Date();
  d3s.setDate(d3s.getDate() - 1);
  const d3e = new Date();
  d3e.setDate(d3e.getDate() - 1);*/

  const calendarComponentRef = useRef();

  /*const [state, setState] = useState({
    calendarEvents: [
      // initial event data
      { title: "LZF/20/54032", start: startTime, end: endTime },
      { title: "LZF/20/54033", start: d1s, end: d1e },
      { title: "LZF/20/54034", start: d2s, end: d2e },
      { title: "LZF/20/54035", start: d3s, end: d3e }
    ]
  })*/

  const [calendarEvents, setCalendarEvents] = useState(data || []);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(calendarEvents)) {
      onChange && onChange(calendarEvents);
    }
  }, [calendarEvents]);

  const handleDateClick = (e) => {
    //console.log("date clicked: " + e);
  };

  const handleDrop = (eventDropInfo) => {
    const { event, revert } = eventDropInfo;

    if (disabledDays.map((d) => d.day).includes(event.start.getDate())) {
      eventDropInfo.revert();
      return;
    }

    const handledEvent = event.toPlainObject();
    setCalendarEvents([
      ...calendarEvents.filter((e) => e.title !== handledEvent.title),
      handledEvent,
    ]);
  };

  return data && initialDate ? (
    <CalendarWrapper>
      <FullCalendar
        initialDate={initialDate}
        locale={skLocale}
        aspectRatio="2"
        height="100%"
        //defaultView="dayGridMonth"
        headerToolbar={{
          left: "",
          center: "",
          right: "",
          /*left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
          */
        }}
        //plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        plugins={[dayGridPlugin, interactionPlugin]}
        ref={calendarComponentRef}
        editable={editable}
        droppable={true}
        events={calendarEvents}
        dateClick={handleDateClick}
        weekends={false}
        displayEventTime={false}
        eventContent={(props) => renderTile({ ...props, editable })}
        dayCellClassNames={(args) =>
          resolveDayCellClasses({ ...args, ...props })
        }
        dayCellContent={(args) => fillDayCellContent({ ...args, ...props })}
        //dayCellDidMount={(args) => resolveEventsEnabled({ ...args, ...props })}
        eventDrop={handleDrop}
        showNonCurrentDates={false}
        fixedWeekCount={false}
      />
    </CalendarWrapper>
  ) : null;
};

Calendar.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  //value: PropTypes.number,
  //color: PropTypes.string,
};

export default Calendar;
