import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import { useRecoilState, useRecoilValue } from "recoil";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import { filterString } from "helpers";
import { useSnackbar } from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { snacks } from "helpers";

import {
  vendorsSelected as vendsSelected,
  vendorSearch,
  isLoading,
  vendorList as vendsList,
} from "store";
import { VENDORS_END } from "config";

import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Tooltip,
  IconButton,
  Divider,
} from "@material-ui/core";

import {
  monthMinusOneName,
  monthMinusTwoName,
  monthMinusOnePlusYear,
  monthMinusTwoPlusYear,
} from "helpers/months";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
    maxHeight: 600,
  },
  macContainer: {
    display: "flex",
    alignItems: "center",
  },
  lightColorAvatar: {
    //marginRight: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
  blueCell: {
    //color: theme.palette.secondary.main
    color: theme.palette.black,
  },
  redCell: {
    color: theme.palette.error.main,
  },
  divider: {
    margin: theme.spacing(1),
  },
  detailButton: {
    marginRight: theme.spacing(1),
  },
}));

/*const statusColors = {
  online: "success",
  offline: "neutral",
};*/

const VendorsTable = (props) => {
  // dummy data
  const { className, ...rest } = props;
  //const { className, ...rest } = props;

  const history = useHistory();

  const classes = useStyles();

  // TODO: temporary commented
  const { data, error } = useSWR(VENDORS_END);
  const [vendorList, setVendorList] = useRecoilState(vendsList);

  //const [vendorList, setVendorList] = useRecoilState(vendsList);

  const [appIsLoading, setAppIsLoading] = useRecoilState(isLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const searchVendor = useRecoilValue(vendorSearch);
  const [selectedVendors, setSelectedVendors] = useRecoilState(vendsSelected);

  const [filteredVendorListLength, setFilteredVendorListLength] = useState(
    vendorSearch
  );

  useEffect(() => {
    if (!data && !error) {
      setAppIsLoading(true);
    } else {
      setAppIsLoading(false);
      if (error) {
        enqueueSnackbar(
          "Informácie o Dohodároch sa nepodarilo získať.",
          snacks.ERROR
        );
        /*if (error.response.status !== 401) {
          enqueueSnackbar(
            "Informácie o Dohodároch sa nepodarilo získať.",
            snacks.ERROR
          );
        }*/
      } else {
        const { vendorList } = data;
        setVendorList(vendorList);
      }
      setSelectedVendors([]);
    }
  }, [data, error]);

  useEffect(() => {
    // go back to first page
    if (page !== 0) {
      setPage(0);
    }
  }, [searchVendor]);

  useEffect(() => {
    if (selectedVendors.length > 0) setSelectedVendors([]);
  }, [searchVendor]);

  const filterData = () => {
    if (searchVendor !== "") {
      const filteredSearch = filterString(searchVendor);
      return (
        vendorList.length > 0 &&
        vendorList.filter((vendor) => {
          return (
            filterString(vendor._id).startsWith(filteredSearch) ||
            filterString(vendor.name).includes(filteredSearch) ||
            filterString(vendor.email).includes(filteredSearch) ||
            (filterString("čaká na potvrdenie").startsWith(filteredSearch) &&
              vendor.records.filter((r) => r.status === 0).length) ||
            (filterString("potvrdená").startsWith(filteredSearch) &&
              vendor.records.filter((r) => r.status === 1).length) ||
            (filterString("prenesená").startsWith(filteredSearch) &&
              vendor.records.filter((r) => r.status === 2).length) ||
            (filterString("ignorovaná").startsWith(filteredSearch) &&
              vendor.records.filter(
                (r) => r.status === 2 && r.month === monthMinusOnePlusYear
              ).length) ||
            (filterString("neukončená").startsWith(filteredSearch) &&
              !vendor.activityEndDate) ||
            (filterString("ukončená").startsWith(filteredSearch) &&
              vendor.activityEndDate)
          );
        })
      );
    } else {
      return vendorList;
    }
  };

  const handleSelectAll = (event) => {
    let selectedVendors;

    if (event.target.checked) {
      const filteredVendorList = filterData(searchVendor);
      selectedVendors = filteredVendorList
        ? filteredVendorList.map((vendor) => vendor._id)
        : [];
      /*vendorList
        ? vendorList.map((vendor) => vendor._id)
        : [];*/
    } else {
      selectedVendors = [];
    }
    setSelectedVendors(selectedVendors);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedVendors.indexOf(id);
    let newSelectedVendors = [];

    if (selectedIndex === -1) {
      newSelectedVendors = newSelectedVendors.concat(selectedVendors, id);
    } else if (selectedIndex === 0) {
      newSelectedVendors = newSelectedVendors.concat(selectedVendors.slice(1));
    } else if (selectedIndex === selectedVendors.length - 1) {
      newSelectedVendors = newSelectedVendors.concat(
        selectedVendors.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedVendors = newSelectedVendors.concat(
        selectedVendors.slice(0, selectedIndex),
        selectedVendors.slice(selectedIndex + 1)
      );
    }

    setSelectedVendors(newSelectedVendors);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const goToVendorDetail = (index, record) => {
    const vendor = filterData(searchVendor)[index]; //vendorList[index];

    history.push({
      pathname: "/vendor-detail",
      state: {
        vendor: {
          _id: vendor._id,
          name: vendor.name,
          email: vendor.email,
          notifications: vendor.notifications,
        },
        record,
      },
    });
  };

  const renderMonthRecord = (index, records, month, currentMonth = false) => {
    const monthRecord = records.filter((r) => r.month === month)[0];

    const WithoutActivity = () => (
      <>
        <Divider orientation="vertical" className={classes.divider} />
      </>
    );

    const TransferredRecord = () => (
      <>
        {"prenesená"}
        <Divider orientation="vertical" className={classes.divider} />
      </>
    );

    const IgnoredRecord = () => (
      <>
        {"ignorovaná"}
        <Divider orientation="vertical" className={classes.divider} />
      </>
    );

    const PendingRecord = () => (
      <>
        {"čaká na potvrdenie"}
        <Divider orientation="vertical" className={classes.divider} />
        <Tooltip title="Upraviť">
          <span>
            <IconButton
              aria-label="refresh"
              className={classes.detailButton}
              onClick={() => goToVendorDetail(index, monthRecord)}
              disabled={appIsLoading}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );

    const ConfirmedRecord = () => (
      <>
        {"potvrdená"}
        <Divider orientation="vertical" className={classes.divider} />
        <Tooltip title="Zobraziť">
          <span>
            <IconButton
              aria-label="refresh"
              className={classes.detailButton}
              onClick={() => goToVendorDetail(index, monthRecord)}
              disabled={appIsLoading}
            >
              <FindInPageIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );

    const RecordCell = () => {
      if (!monthRecord) {
        return <WithoutActivity />;
      } else {
        switch (monthRecord.status) {
          case 0:
            return <PendingRecord />;
          case 1:
            return <ConfirmedRecord />;
          case 2:
            if (currentMonth) {
              return <IgnoredRecord />;
            } else {
              return <TransferredRecord />;
            }
        }
      }
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RecordCell />
      </div>
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        vendorList &&
                        selectedVendors.length ===
                          filterData(searchVendor).length
                          ? true
                          : false
                      }
                      color="secondary"
                      indeterminate={
                        selectedVendors.length > 0 &&
                        selectedVendors.length < data.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="center">
                    Osobné <br /> číslo
                  </TableCell>
                  <TableCell>Meno</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">
                    {`Dochádzka za`}
                    <br />
                    {monthMinusTwoName}
                    {/*Predchádzajúci <br /> mesiac*/}
                  </TableCell>
                  <TableCell align="center">
                    {`Dochádzka za`}
                    <br />
                    {monthMinusOneName}
                    {/*Aktuálny <br /> mesiac*/}
                  </TableCell>
                  <TableCell align="center">
                    Ukončená <br /> činnosť
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/*//data.slice(0, rowsPerPage).map(vendor => ( //(vendorList &&
                vendorList.length > 0) ? filterData(searchVendor).slice(0,
                rowsPerPage).map(vendor => (*/}
                {vendorList && vendorList.length > 0
                  ? filterData(searchVendor)
                      .slice(0 + rowsPerPage * page, rowsPerPage * (page + 1))
                      .map((vendor, index) => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={vendor._id}
                          selected={selectedVendors.indexOf(vendor._id) !== -1}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={
                                selectedVendors.indexOf(vendor._id) !== -1
                              }
                              color="secondary"
                              onChange={(event) =>
                                handleSelectOne(event, vendor._id)
                              }
                              value="true"
                            />
                          </TableCell>
                          <TableCell align="center">
                            {/*<div className={classes.macContainer}>
                          <StatusBullet
                            className={classes.status}
                            color={statusColors[vendor.online ? 'online' : 'offline']}
                            size="sm"
                          />
                          <Typography variant="body1">{vendor._id}</Typography>
                </div>*/}
                            <Typography variant="body1">
                              {vendor._id}
                            </Typography>
                          </TableCell>
                          <TableCell>{vendor.name}</TableCell>
                          <TableCell>{vendor.email}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {renderMonthRecord(
                                index,
                                vendor.records,
                                monthMinusTwoPlusYear
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {renderMonthRecord(
                                index,
                                vendor.records,
                                monthMinusOnePlusYear,
                                true
                              )}
                            </div>

                            {/*getActiveBay(vendor.activeBay)*/}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              /*color: vendor.bays[0].fragrance.color ?
                            `#${vendor.bays[0].fragrance.color}`
                            : `#ffffff`, */
                              fontWeight: "bold",
                            }}
                          >
                            {vendor.activityEndDate ? "ukončená" : "neukončená"}
                            {/*<Tooltip title={
                          fragranceOnSchedule(vendor, 0) === false && vendor.expectedFragrance ?
                            (vendor.expectedFragrance.name ? vendor.expectedFragrance.name : vendor.expectedFragrance.code)
                            : ''
                        }>
                          <span>
                            {vendor.bays[0].fragrance.name ?
                              vendor.bays[0].fragrance.name
                              : vendor.bays[0].fragrance.code
                            }
                          </span>
                          </Tooltip>*/}
                          </TableCell>
                          {/*<Tooltip title={
                          fragranceOnSchedule(vendor, 1) === false && vendor.expectedFragrance ?
                            (vendor.expectedFragrance.name ? vendor.expectedFragrance.name : vendor.expectedFragrance.code)
                            : ''
                        }>
                          <span>
                            {
                              vendor.bays[1].fragrance.name ?
                                vendor.bays[1].fragrance.name
                                : vendor.bays[1].fragrance.code
                            }
                          </span>
                          </Tooltip>*/}
                        </TableRow>
                      ))
                  : null}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={vendorList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

VendorsTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default VendorsTable;
