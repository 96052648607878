import React, { useState, useEffect } from "react";
import { RecoilRoot } from "recoil";
import RecoilLogger from "recoil-logger";
import recoilPersist from "recoil-persist";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { SWRConfig } from "swr";
import { Helmet } from "react-helmet";

// create once hash to any inline scripts and styles (CSP support)
import './helpers/create-nonce';


import theme from "./theme";
import Routes from "./Routes";
import Authenticator from "./Authenticator";

import axios from "axios";
import { getBaseAPIUrl } from "config";
import { getData } from "fetchers";

// TODO: could be imported only in case of dev env setup
import { isDev } from "helpers";
import useReload from "./Reload";


axios.default.baseURL = getBaseAPIUrl();

const { RecoilPersist, updateState } = recoilPersist(
  ['loggedUser'], // configurate that atoms will be stored (if empty then all atoms will be stored),
  {
    key: 'recoil-persist', // this key is using to store data in local storage
    storage: localStorage, // configurate which stroage will be used to store the data
  }
);

function App() {
  //const [data, setData] = useState(null);

  // TODO: DEBUGGIN PURPOSE // check if is dev
  //const reload = useReload();

  return (
    <RecoilRoot
      initializeState={({ set }) => {
        updateState({ set });
      }}
    >
      <RecoilPersist />
      {isDev() ? <RecoilLogger /> : null}
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          preventDuplicate
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
        >
          <SWRConfig
            value={{
              dedupingINterval: 2000,
              fetcher: getData,
            }}
          >
            <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
              <meta property="csp-nonce" content="Mjk2Yzc0MmMtNGRlMy00Y2YxLWFlYjItODQ4MjhjNTRhZGY4" />
            </Helmet>
            <Authenticator>
              <Router>
                <Routes />
              </Router>
            </Authenticator>
          </SWRConfig>
        </SnackbarProvider>
      </ThemeProvider>
    </RecoilRoot>
  );

  // TODO: remove
  /*useEffect(() => {
    const loadData = async () => {
      const callPong = async () => {
        const response = await fetch('/ping');
        const body = await response.json();


        if (response.status !== 200) {
          throw Error(body.message)
        }
        return body;
      }

      try {
        const res = await callPong();
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    }
    loadData();
  }, []);

  return (
    <div className="App" >
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>k</h2>
      </header>
      <main>
        <p>
          {`fetched data: ${data}`}
        </p>
      </main>
    </div>
  );
  */
}

export default App;
