import moment from "moment";
import "moment/locale/sk";
moment.locale("sk");

const capitalise = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const monthMinusOneName = capitalise(
  moment().subtract(1, "month").startOf("month").format("MMMM")
);
export const monthMinusTwoName = capitalise(
  moment().subtract(2, "month").startOf("month").format("MMMM")
);

export const monthMinusOnePlusYear = moment()
  .subtract(1, "month")
  .format("YYYYMM");

export const monthMinusTwoPlusYear = moment()
  .subtract(2, "month")
  .format("YYYYMM");

export const getMonthName = (month) =>
  capitalise(
    moment()
      .set({ month: month - 1 })
      .format("MMMM")
  );
