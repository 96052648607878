import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { Calendar, ConfirmationModal } from "components";
import { VDToolbar, Info, VDNotifModal } from "./components";
import { useSnackbar } from "notistack";
import { snacks } from "helpers";
import { RECORDS_END, VENDORS_END, HOLIDAYS_END } from "config";
import { patchData, postData, getData } from "fetchers";
import { isLoading } from "store";
import { loggedUser as user } from "store";

import mockData from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  centered: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "32px",
  },
  marginBottom: {
    marginBottom: "32px",
  },
}));

export const VendorDetailContext = React.createContext({
  // confirmation
  confirmationOpen: false,
  setConfirmationOpen: () => {},
  // info
  infoOpen: false,
  setInfoOpen: () => {},
  // actionsEnabled
  actionsEnabled: false,
  setActionsEnabled: () => {},
  // handle records
  handledRecord: null,
  setHandledRecord: () => {},
});

const VendorDetail = (props) => {
  const classes = useStyles();

  const notificationId = props.match.params.id;

  const viewType = notificationId ? "vendor" : "admin";

  const loggedUser = useRecoilValue(user);
  const [appIsLoading, setAppIsLoading] = useRecoilState(isLoading);
  const { enqueueSnackbar } = useSnackbar();

  // ***** state *****
  const [state, setState] = useState(
    props.history.location?.state || {
      vendor: null,
      record: null,
      editable: null,
      calendarData: null,
    }
  );

  const [holidays, setHolidays] = useState(null);

  const [yearMonth, setYearMonth] = useState(null);
  const [actionsEnabled, setActionsEnabled] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const [handledRecord, setHandledRecord] = useState(null);

  const [notifInvalid, setNotifInvalid] = useState(false);
  const [notifChecked, setNotifChecked] = useState(false);

  // *****************

  useEffect(() => {
    const { vendor, record } = state;
    if (!vendor && !record) {
      (async () => {
        try {
          setAppIsLoading(true);
          setActionsEnabled(false);
          // TODO: create API call , get vendors details by notification
          const { vendor, record } = await getData(
            `${RECORDS_END}/${notificationId}`
          );

          const holidays = await getData(`${HOLIDAYS_END}/${record.month}`);

          setState({
            record,
            vendor,
            editable: prepareEditable(record),
            calendarData: prepareCalendarData(record),
          });
          setHolidays(holidays);
          setHandledRecord(record);
          setAppIsLoading(false);
          setActionsEnabled(true);
        } catch (err) {
          const { status } = err.response;
          if (status === 400) {
            setNotifChecked(true);
            setNotifInvalid(true);
          } else {
            enqueueSnackbar(err.message, snacks.ERROR);
            setAppIsLoading(false);
            setActionsEnabled(true);
          }
        }
      })();
    } else {
      (async () => {
        const holidays = await getData(`${HOLIDAYS_END}/${record.month}`);

        setState({
          ...state,
          editable: prepareEditable(record),
          calendarData: prepareCalendarData(record),
        });
        setHolidays(holidays);

        setHandledRecord(record);
      })();
    }
  }, []);

  useEffect(() => {
    const { record } = state;
    if (record) {
      setYearMonth([record.month.slice(0, 4), record.month.slice(4)].join("-"));
    }
  }, [state]);

  const prepareCalendarData = (record) => {
    let calendarData = [];
    let yearMonth = [record.month.slice(0, 4), record.month.slice(4)].join("-");
    record.days.forEach((d) => {
      let date = new Date(
        `${yearMonth}-${d.number < 10 ? `0${d.number}` : d.number}`
      );

      const contractsData = d.contracts.map((c, index) => {
        return {
          title: c,
          start: date.setHours(index + 1),
          end: date.setHours(index + 1),
        };
      });

      calendarData = [...calendarData, ...contractsData];
    });
    return calendarData;
  };

  const prepareEditable = (record) => {
    switch (record.status) {
      case 0:
        return true;
      case 1:
        return false;
      default:
        return false;
    }
  };

  const updateRecord = async () => {
    try {
      setActionsEnabled(false);
      setAppIsLoading(true);
      if (loggedUser) {
        /*const res = await patchData(RECORDS_END, handledRecord);
        enqueueSnackbar(
          `Dochádzka bola odoslaná na potvrdenie.`,
          snacks.SUCCESS
        );*/
        const res = await postData(
          `${VENDORS_END}/${vendor._id}/notifications`,
          handledRecord
        );
        enqueueSnackbar(
          `Dochádzka bola odoslaná na potvrdenie.`,
          snacks.SUCCESS
        );
        setState({
          ...state,
          editable: false,
        });
        setActionsEnabled(false);
      } else {
        const res = await patchData(
          `${RECORDS_END}/${notificationId}`,
          handledRecord
        );
        enqueueSnackbar(`Dochádzka bola úspešne potvrdená.`, snacks.SUCCESS);
        setState({
          ...state,
          editable: false,
        });
        setActionsEnabled(false);
      }
      setAppIsLoading(false);
    } catch (err) {
      enqueueSnackbar(err.message, snacks.ERROR);
      setAppIsLoading(false);
      setActionsEnabled(true);
    }
  };

  const onCalendarChange = (calendarEvents) => {
    const days = [];
    calendarEvents.forEach((e) => {
      const number = new Date(e.start).getDate();
      let dayToUpdate = days.filter((d) => d.number === number)[0];
      if (dayToUpdate) {
        dayToUpdate.contracts.push(e.title);
      } else {
        days.push({
          number,
          contracts: [e.title],
        });
      }
    });

    setHandledRecord({
      _id: record._id,
      days,
    });

    setActionsEnabled(true);
  };

  if (!notificationId && !state.vendor) {
    return <Redirect to="/vendors" />;
  }

  const { vendor, record, editable, calendarData } = state;

  return vendor &&
    record &&
    typeof editable === "boolean" &&
    calendarData &&
    Array.isArray(holidays) &&
    (!notifInvalid || loggedUser) ? (
    <VendorDetailContext.Provider
      value={{
        // confirmation
        confirmationOpen,
        setConfirmationOpen,
        // info
        infoOpen,
        setInfoOpen,
        // actionsEnables
        actionsEnabled,
        setActionsEnabled,
        // handled record
        handledRecord,
        setHandledRecord,
      }}
    >
      <ConfirmationModal
        modalOpen={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onNegative={() => setConfirmationOpen(false)}
        onPositive={() => updateRecord()}
        title="Upozornenie"
        message={
          viewType === "admin"
            ? "Naozaj si prajete odoslať dochádzku na potvrdenie?"
            : "Potvrdenie dochádzky je záväzné a nemenné. Naozaj si prajte dochádzku potvrdiť?"
        }
      />
      {viewType === "admin" ? (
        <VDNotifModal
          modalOpen={infoOpen}
          onClose={() => setInfoOpen(false)}
          notifications={vendor.notifications}
        />
      ) : null}

      <div className={classes.root}>
        <VDToolbar
          type={viewType}
          vendor={vendor}
          yearMonth={record.month}
          actionsEnabled={actionsEnabled}
          isConfirmed={record.confirmed}
        />
        <Grid container>
          <Grid
            item
            xs={12}
            //lg={9}
            //xl={9}
            //md={12}
            //xs={12}
          >
            <Calendar
              initialDate={yearMonth ? `${yearMonth}-01` : null}
              data={calendarData}
              editable={editable || actionsEnabled}
              onChange={onCalendarChange}
              disabledDays={holidays}
            />
          </Grid>
        </Grid>
      </div>
    </VendorDetailContext.Provider>
  ) : notifChecked ? (
    <div className={classes.root}>
      <div className={classes.centered}>
        <h2 className={classes.marginBottom}>Ľutujeme,</h2>
        <p>platnosť URL adresy vypršala.</p>
      </div>
    </div>
  ) : null;
};

export default VendorDetail;
