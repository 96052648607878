import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { mutate } from "swr";
import { makeStyles } from "@material-ui/styles";
import { ConfirmationModal } from "components";
import { VendorsToolbar, VendorsTable } from "./components";
import { isLoading } from "store";

import { ATTENDANCE_END } from "config";
import { getData, postData } from "fetchers";
import FileDownload from "js-file-download";

import mockData from "./data";
import { VENDORS_END } from "config";

import { useSnackbar } from "notistack";
import { snacks } from "helpers";

import {
  vendorList as vendsList,
  vendorsSelected as vendsSelected,
} from "store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export const VendorListContext = React.createContext({
  // confirmation
  exportConfirmationOpen: false,
  setExportConfirmationOpen: () => {},
});

const VendorList = () => {
  const classes = useStyles();

  const [vendors] = useState(mockData);

  const [appIsLoading, setAppIsLoading] = useRecoilState(isLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [vendorList, setVendorList] = useRecoilState(vendsList);

  const [selectedVendors, setSelectedVendors] = useRecoilState(vendsSelected);

  // ***** state *****
  const [exportConfirmationOpen, setExportConfirmationOpen] = useState(false);
  const [sendNotifConfirmationOpen, setSendNotifConfirmationOpen] = useState(
    false
  );

  const [vendorCounts, setVendorCounts] = useState({
    confirmed: 0,
    unconfirmed: 0,
  });
  const [vendorCountError, setVendorCountError] = useState(false);

  // *****************

  const handleExport = async () => {
    setAppIsLoading(true);
    const res = await getData(
      ATTENDANCE_END + "/export",
      {
        "csobl-close": "true",
      },
      null,
      "blob"
    );
    FileDownload(res, "Dochadzka.xlsx");
    mutate(VENDORS_END);
    mutate(ATTENDANCE_END);
    setAppIsLoading(false);
  };

  const prepareDataForNotificationSending = async () => {
    try {
      setAppIsLoading(true);
      const { vendorList } = await getData(`${VENDORS_END}`, {
        list: selectedVendors,
        current: true,
      });

      let confirmed = 0;
      let unconfirmed = 0;
      vendorList.forEach((v) => {
        const { records } = v;
        switch (records[records.length - 1].status) {
          case 0:
            unconfirmed += 1;
            break;
          case 1:
            confirmed += 1;
          default:
            break;
        }
      });

      setVendorCounts({
        confirmed,
        unconfirmed,
      });

      setSelectedVendors(vendorList.map((v) => v._id));
      setVendorCountError(false);
      setAppIsLoading(false);
    } catch (err) {
      setVendorCountError(true);
      setAppIsLoading(false);
      const status = err.reponse?.status;
      enqueueSnackbar(
        "Informácie o Dohodároch sa nepodarilo získať.",
        snacks.ERROR
      );
    }
  };

  const handleSendNotifications = () => {
    try {
      (async () => {
        setAppIsLoading(true);
        const res = await postData(`${VENDORS_END}/notifications`, {
          targets: selectedVendors,
        });
        enqueueSnackbar(`Notifikácie boli odoslané.`, snacks.SUCCESS);
        setAppIsLoading(false);

      })();
      setSelectedVendors([]);
      setVendorCounts({
        confirmed: 0,
        unconfirmed: 0,
      });
    } catch (err) {
      const { status } = err.response;
      enqueueSnackbar(err.message, snacks.ERROR);
      setAppIsLoading(false);
    }
  };

  return (
    <VendorListContext.Provider
      value={{
        // confirmation
        setSendNotifConfirmationOpen,
        setExportConfirmationOpen,
      }}
    >
      <ConfirmationModal
        isLoading={appIsLoading}
        onOpen={prepareDataForNotificationSending}
        modalOpen={sendNotifConfirmationOpen}
        errorOccured={vendorCountError}
        onClose={() => setSendNotifConfirmationOpen(false)}
        onNegative={() => setSendNotifConfirmationOpen(false)}
        onPositive={() => handleSendNotifications()}
        title="Upozornenie"
        message={
          !vendorCountError ? (
            <>
              <span>
                Prajete si odoslať notifikácie:
                <br />
                {vendorCounts.confirmed > 0 ? (
                  <span>
                    <br />
                    <b>{vendorCounts.confirmed}</b> dohodárom, s{" "}
                    <b>potvrdenou</b> dochádzkou
                  </span>
                ) : null}
                <br />
                {vendorCounts.unconfirmed > 0 ? (
                  <span>
                    <b>{vendorCounts.unconfirmed}</b> dohodárom, s{" "}
                    <b>nepotvrdenou</b> dochádzkou
                  </span>
                ) : null}
                <br />
              </span>
              <br />
              <span>
                <b>Táto akcia je nevratná! Prajete si pokračovať?</b>
              </span>
            </>
          ) : (
            <>
              <span>
                <b>
                  Informácie o dohodároch sa nepodarilo získať. Opakujte pokus
                  neskôr.
                </b>
              </span>
            </>
          )
        }
      />
      <ConfirmationModal
        modalOpen={exportConfirmationOpen}
        onClose={() => setExportConfirmationOpen(false)}
        onNegative={() => setExportConfirmationOpen(false)}
        onPositive={() => handleExport()}
        title="Upozornenie"
        message={
          <span>
            Naozaj si prajete uzavrieť dochádzku? <b>Táto akcia je nevratná!</b>
          </span>
        }
      />
      <div className={classes.root}>
        <VendorsToolbar />
        <div className={classes.content}>
          <VendorsTable data={vendors} />
        </div>
      </div>
    </VendorListContext.Provider>
  );
};

export default VendorList;
