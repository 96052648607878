/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors, Hidden, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.light
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { items, className, ...rest } = props;


  const classes = useStyles();

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {items.map(item => {
        let buttonProps = {
          className: classes.button,
          component: item.href ? CustomRouterLink : 'div'
        }

        if (item.href) {
          buttonProps.activeClassName = classes.active
          buttonProps.to = item.href
        }

        if (item.onClick)
          buttonProps.onClick = item.onClick

        const Item = (props) =>
          <ListItem
            className={classes.item}
            disableGutters
            style={{
              marginTop: item.logOut ? '35px' : null
            }}
          >
            <Button
              {...buttonProps}
            >
              <div className={classes.icon}>{item.icon}</div>
              {item.title}
            </Button>
          </ListItem>

        return (
          <div key={item.title}>
            {
              item.logOut ?
                <Hidden
                  lgUp
                >
                  <Divider />
                  <Item />
                </Hidden>
                : <Item />
            }
          </div>
        )
      })}
    </List >
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default SidebarNav;
