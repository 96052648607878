import React, { useRef, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useRecoilState, useRecoilValue } from "recoil";
import useSWR from "swr";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  IconButton,
  Tooltip,
  Box,
  Typography,
  Button,
  Grid,
  Link,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { snacks } from "helpers";
import { vendorSearch, vendors } from "store";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NotificationsIcon from "@material-ui/icons/Notifications";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { LinearProgress } from "components";

import { isLoading } from "store";
import { VENDORS_END } from "config";
import { getData } from "fetchers";
import { getMonthName } from "helpers/months";

import { VendorDetailContext } from "../../VendorDetail";
import { VDInfo } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    minHeight: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  refreshButton: {
    marginRight: theme.spacing(1),
  },
  lastUpdate: {
    marginRight: theme.spacing(2),
  },
  lockButton: {
    marginRight: theme.spacing(2),
  },
  spaceAllocator: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "900px",
    //padding: theme.spacing(4),
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  infoPart: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: '8px',
      justifyContent: 'space-between'
    }
  },
  centered: {
    //position: "relative",
    //margin: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "32px",
    [theme.breakpoints.down('sm')]: {
      margin: "8px 0",
      justifyContent: "space-between"
    }

    /*"& h2": {
      marginLeft: "100%"
    },*/
    /*
"& .divider": {
  flex: 1
    },
    "& Button": {

      alignSelf: "flex-end"
    },
    */
  },
}));

const VDToolbar = (props) => {
  const {
    className,
    type,
    vendor,
    isConfirmed,
    yearMonth,
    actionsEnabled,
    ...rest
  } = props;
  const history = useHistory();

  const classes = useStyles();

  //const { data, mutate } = useSWR(VENDORS_END);

  const { enqueueSnackbar } = useSnackbar();

  const [appIsLoading, setAppIsLoading] = useRecoilState(isLoading);

  /*const [countDown, setCountDown] = useState(0);

  const [refreshInterval, setRefreshInterval] = useState(null);
  */
  const ctx = useContext(VendorDetailContext);

  const handleSubmit = async () => {
    ctx.setConfirmationOpen(true);
  };

  const handleSendForSubmission = async () => {
    ctx.setConfirmationOpen(true);
  };

  const handleShowNotifs = async () => {
    ctx.setInfoOpen(true);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleLockClick = () => {
    ctx.setActionsEnabled(!actionsEnabled);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        {type === "admin" ? (
          <Tooltip title="Späť">
            <IconButton onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        ) : null}

        <span className={classes.spacer} />
        {appIsLoading ? <LinearProgress value={null} /> : null}
        {type === "admin" ? (
          <>
            {isConfirmed ? (
              <Tooltip
                title={
                  !actionsEnabled
                    ? "Odomnknúť dochádzku"
                    : "Uzatvoriť dochádzku"
                }
              >
                <IconButton
                  className={classes.lockButton}
                  onClick={handleLockClick}
                >
                  {!actionsEnabled ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>
              </Tooltip>
            ) : null}
            <Button
              className={classes.controlButton}
              color="primary"
              variant="contained"
              onClick={handleSendForSubmission}
              disabled={!actionsEnabled}
            >
              Odoslať na potvrdenie
            </Button>
          </>
        ) : (
            <Button
              className={classes.controlButton}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={!actionsEnabled}
            >
              Potvrdiť
            </Button>
          )}

        {/*<Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add user
        </Button>*/}
      </div>
      <div className={classes.row}>
        <div className={classes.centered}>
          <span className={classes.spacer} />
          <Typography variant="h2" component="p">
            {`${getMonthName(yearMonth.slice(4))} ${yearMonth.slice(0, 4)}`}
          </Typography>
          <span className={classes.spacer} />
          {type === "admin" ? (
            <Tooltip title="Notifikácie">
              <IconButton onClick={handleShowNotifs}>
                <NotificationsIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
      {type === "vendor" ? (
        <div className={classes.row}>
          <VDInfo />
        </div>
      ) : null}
      <div className={classes.row}>
        <div className={classes.spaceAllocator}>
          <div className={classes.infoPart}>
            <Typography variant="body1">Osobné číslo:</Typography>
            <Typography variant="body1">{vendor._id}</Typography>
          </div>
          <div className={classes.infoPart}>
            <Typography variant="body1">Meno:</Typography>
            <Typography variant="body1">
              <Box component="span" fontWeight="fontWeightBold">
                {vendor.name}
              </Box>
            </Typography>
          </div>
          <div className={classes.infoPart}>
            <Typography variant="body1">Email:</Typography>
            <Typography>
              <Link href={`mailto:${vendor.email}`} variant="body1">
                {vendor.email}
              </Link>
            </Typography>
          </div>
        </div>

        {/*
        <Tooltip title="Obnoviť">
          <IconButton
            aria-label="refresh"
            className={classes.refreshButton}
            onClick={handleRefresh}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Tooltip>
       */}
      </div>
    </div>
  );
};

VDToolbar.propTypes = {
  className: PropTypes.string,
};

export default VDToolbar;
