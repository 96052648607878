import palette from '../palette';

export default {
  root: {
    '&$focused': {
      color: `${palette.secondary.main}`
    },
  },
  focused: {},
}




