import React, { useState, useEffect } from "react";
import { Link as RouterLink, Redirect, withRouter } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { snacks } from "helpers";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AuthImage from "assets/csob-be.jpg";

import { isLoading, loggedUser } from "store";
import { postData } from "fetchers";
import { LOST_PASS_END } from "config";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "nemôže byť prázdny." },
    email: {
      message: "je nesprávny.",
    },
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.default,
      height: "calc(100vh - 64px)",
    },
    grid: {
      height: "100%",
    },
    quoteContainer: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    quote: {
      backgroundColor: theme.palette.neutral,
      height: "100%",
      display: "flex",
      paddingTop: "100px",
      justifyContent: "center",
      alignItems: "flex-start",
      backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`, //'url(/images/auth.jpg)',
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    quoteInner: {
      textAlign: "center",
      flexBasis: "600px",
    },
    quoteText: {
      color: theme.palette.white,
      fontWeight: 300,
    },
    name: {
      marginTop: theme.spacing(3),
      color: theme.palette.white,
    },
    bio: {
      color: theme.palette.white,
    },
    contentContainer: {},
    content: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentHeader: {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(5),
      paddingBototm: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    logoImage: {
      marginLeft: theme.spacing(4),
    },
    contentBody: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
    },
    form: {
      paddingLeft: 100,
      paddingRight: 100,
      paddingBottom: 125,
      flexBasis: 700,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    title: {
      margin: theme.spacing(3, 0),
    },
    /*socialButtons: {
      marginTop: theme.spacing(3)
    },
    socialIcon: {
      marginRight: theme.spacing(1)
    },*/
    sugestion: {
      marginTop: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    resetButton: {
      margin: theme.spacing(2, 0),
    },
  };
});

const LostPassword = (props) => {
  const { history } = props;

  const classes = useStyles({ backgroundImage: AuthImage });

  const [appIsLoading, setAppIsLoading] = useRecoilState(isLoading);
  const { enqueueSnackbar } = useSnackbar();

  const user = useRecoilValue(loggedUser);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleReset = async (event) => {
    event.preventDefault();

    try {
      setAppIsLoading(true);

      //console.log(formState.values.email);
      const res = await postData(LOST_PASS_END, {
        email: formState.values.email,
      });
      enqueueSnackbar(
        "Na Váš email bol odoslaný link pre zmenu hesla.",
        snacks.SUCCESS
      );
      history.push("/");
    } catch (err) {
      const { status } = err?.response;
      if (status === 404) {
        enqueueSnackbar("Používateľ neexistuje.", snacks.ERROR);
      }
    } finally {
      setAppIsLoading(false);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return user && user.token ? (
    <Redirect to="/" />
  ) : (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  ČSOBL Dochádzkový systém
              </Typography>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleReset}>
                  <Typography className={classes.title} variant="h2">
                    Zabudnuté heslo
                </Typography>

                  <TextField
                    className={classes.textField}
                    error={hasError("email")}
                    fullWidth
                    helperText={
                      hasError("email") ? formState.errors.email[0] : null
                    }
                    label="Emailová adresa"
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ""}
                    variant="outlined"
                    autoComplete="off"
                  />
                  <Button
                    className={classes.resetButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {!appIsLoading ? (
                      "Resetovať heslo"
                    ) : (
                        <CircularProgress color="secondary" size={26} />
                      )}
                  </Button>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
};

LostPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(LostPassword);
