import { atom, selector } from "recoil";

// app
export const isLoading = atom({
  key: "isLoading",
  default: false,
  persistence_UNSTABLE: {
    type: "log",
  },
});

export const loggedUser = atom({
  key: "loggedUser",
  default: null,
  persistence_UNSTABLE: {
    type: "log",
  },
});

export const confirmationModal = atom({
  key: "confirmationModal",
  default: false,
  persistence_UNSTABLE: {
    type: "log",
  },
});

export const help = atom({
  key: "help",
  default: false,
  persistence_UNSTABLE: {
    type: "log",
  },
});

// vendors view
export const vendorList = atom({
  key: "vendorList",
  default: [],
  persistence_UNSTABLE: {
    type: "log",
  },
});

export const vendorSearch = atom({
  key: "vendorSearch",
  default: "",
  persistence_UNSTABLE: {
    type: "log",
  },
});

export const vendorsSelected = atom({
  key: "vendorsSelected",
  default: [],
  persistence_UNSTABLE: {
    type: "log",
  },
});

export const handledVendor = atom({
  key: "handledVendor",
  default: {
    on: true,
  },
  persistence_UNSTABLE: {
    type: "log",
  },
});
