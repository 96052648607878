import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import CSOBLogo from 'assets/csob-logo.png';

import styled from 'styled-components';

const LogoImg = styled.img`
  width: 128px;
`

const RestyledRouterLink = styled(RouterLink)`
  && {
    text-decoration: none;
  }
`

const RestyledTypography = styled(Typography)`
  && {
    color: white;
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    //boxShadow: 'none'
  },
  logoImg: {
    marginLeft: theme.spacing(1)
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RestyledRouterLink to="/">
         {/*<RestyledTypography variant="h3">Pura IFF testing platform</RestyledTypography>*/}
          <LogoImg
            className={classes.logoImg}
            alt="Logo"
            src={CSOBLogo}
          />
        </RestyledRouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
