import axios from 'axios';
import progress from "progress-stream";

export default async (url, data, onProgress = null) => {
  return (axios.request({
    method: "patch",
    url: url,
    data,
    onUploadProgress: (p) => {
      onProgress && onProgress(p);
    }
  })).then(res => res.data)
}