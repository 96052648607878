import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout, GuardedRouteWithLayout } from "./components";

import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  //Dashboard as DashboardView,
  VendorList as VendorsView,
  NotFound as NotFoundView,
  SignIn as SignInView,
  LostPassword as LostPasswordView,
  ChangePassword as ChangePasswordView,
  VendorDetail as VendorDetailView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/vendors" />
      <GuardedRouteWithLayout
        component={VendorsView}
        exact
        layout={MainLayout}
        path="/vendors"
      />
      <GuardedRouteWithLayout
        component={VendorDetailView}
        exact
        layout={MainLayout}
        path="/vendor-detail"
      />
      <RouteWithLayout
        component={VendorDetailView}
        layout={MinimalLayout}
        path="/vendor-detail/:id"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={LostPasswordView}
        exact
        layout={MinimalLayout}
        path="/lost-password"
      />
      <RouteWithLayout
        component={ChangePasswordView}
        exact
        layout={MinimalLayout}
        path="/change-password/:id"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
