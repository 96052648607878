import axios from "axios";
import progress from "progress-stream";

export default (
  url,
  head = null,
  prms = null,
  resType = null,
  onProgress = null
) => {
  // headers
  let headers = {};

  const commaSeparatedList =
    head && head.list
      ? head.list.reduce((acc, cur) => acc + cur + ",", "").slice(0, -1)
      : null;

  if (commaSeparatedList) {
    headers["targets"] = commaSeparatedList;
    delete head.list;
  }

  if (head !== null) {
    headers = { ...headers, ...head };
  }

  //params
  let params = {};

  if (params !== null) {
    params = { ...prms };
  }

  //reponseType
  let responseType = "";
  if (resType !== null) {
    responseType = resType;
  }

  /*let headers = {
    ...axios.defaults.headers.common
  }*/


  return axios
    .request({
      method: "get",
      url: url,
      headers,
      params,
      responseType,
      onDownloadProgress: (p) => {
        onProgress && onProgress(p);
      },
      transformResponse: [
        (data) => {
          try {
            const dataObj = JSON.parse(data);
            return dataObj;
          } catch (err) {
            return data;
          }
        },
        ...axios.defaults.transformResponse,
      ],
    })
    .then((res) => {
      return res.data;
    });
};
