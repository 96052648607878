import palette from '../palette';

export default {
  root: {
      color: `${palette.primary.contrastText}`
  },
}




