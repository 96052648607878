import axios from "axios";
import progress from "progress-stream";

export default (url, data, onProgress = null) => {
  return axios
    .request({
      method: "post",
      url: url,
      data: data,
      onUploadProgress: (p) => {
        onProgress && onProgress(p);
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw {
        ...err,
        ...err.response,
      };
    });
};
