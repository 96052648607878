const snacks = {
  SUCCESS: {
    variant: 'success'
  },
  ERROR: {
    variant: 'error'
  },
  WARNING: {
    variant: 'warning'
  },
  INFO: {
    variant: 'info'
  }
}

Object.freeze(snacks);

export default snacks;