import React, { useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Typography } from '@material-ui/core';

import CSOBLogo from 'assets/csob-logo.png';

import styled from 'styled-components';
import { isLoading, help, confirmationModal } from 'store';

const LogoImg = styled.img`
  width: 128px;
`

const RestyledRouterLink = styled(RouterLink)`
  && {
    text-decoration: none;
  }
`

const RestyledTypography = styled(Typography)`
  && {
    color: white;
  }
`


const useStyles = makeStyles(theme => ({
  root: {
    //boxShadow: 'none'
  },
  logoImg: {
    marginLeft: theme.spacing(1)
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  helpButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)

  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const appIsLoading = useRecoilValue(isLoading);
  const [openHelp, setOpenHelp] = useRecoilState(help);
  const [openSignOut, setOpenSignOut] = useRecoilState(confirmationModal);

  const [notifications] = useState([]);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RestyledRouterLink to="/">
          <LogoImg
            className={classes.logoImg}
            alt="Logo"
            src={CSOBLogo}
          />
        </RestyledRouterLink>
        <div className={classes.flexGrow} />
        <IconButton
          className={classes.helpButton}
          onClick={() => setOpenHelp(true)}
          color="inherit"
        >
          <HelpOutlineIcon />
        </IconButton>

        <Hidden mdDown>
          {/*<IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>*/}

          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={() => setOpenSignOut(true)}
          >
            <ExitToAppIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
            disabled={appIsLoading}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
