import React from "react";
import { makeStyles } from "@material-ui/styles";
import { InfoModal } from "components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { StatusBullet } from "components";

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from "@material-ui/core";
import {
  Flight as CreatedIcon,
  FlightTakeoff as SentIcon,
  FlightLand as DeliveredIcon,
  LibraryBooks as SeenIcon,
  LibraryAddCheck as ConfirmedIcon,
  /*RadioButtonChecked as ValidIcon,
  RadioButtonUnchecked as InvalidIcon,*/
} from "@material-ui/icons";

import "react-perfect-scrollbar/dist/css/styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    maxHeight: 400,
  },
  status: {
    marginRight: theme.spacing(1),
  },
}));

const VDNotifModal = (props) => {
  const { modalOpen, onClose, notifications } = props;
  const classes = useStyles();


  /*const notifs = [
    ...notifications,
    {
      _id: "a",
      status: "sent",
      updatedAt: "a",
      valid: true,
    },
    {
      _id: "b",
      status: "delivered",
      updatedAt: "a",
    },
    {
      _id: "c",
      status: "read",
      updatedAt: "a",
    },
    {
      _id: "d",
      status: "confirmed",
      updatedAt: "a",
    },
  ];*/

  const resolveNotif = (notification) => {
    const { valid, status } = notification;

    let result = null;

    switch (status) {
      case "created":
        result = [<CreatedIcon />, "vytvorená"];
        break;
      case "sent":
        result = [<SentIcon />, "odoslaná"];
        break;
      case "delivered":
        result = [<DeliveredIcon />, "doručená"];
        break;
      case "read":
        result = [<SeenIcon />, "videná"];
        break;
      case "confirmed":
        result = [<ConfirmedIcon />, "potvrdená"];
        break;
      default:
        break;
    }

    result[1] = (
      <div>
        <StatusBullet
          className={classes.status}
          color={valid ? "success" : "danger"}
          size="sm"
        />
        {result[1]}
      </div>
    );
    return result;
  };

  const formDate = (date) => {
    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(date).toLocaleDateString("sk-SK", dateOptions);
  };

  return (
    <InfoModal modalOpen={modalOpen} onClose={onClose} title="Notifikácie">
      <PerfectScrollbar>
        <List className={classes.root}>
          {notifications.map((n) => (
            <div key={n._id}>
              <ListItem key={n._id}>
                <ListItemAvatar>
                  <Avatar>{resolveNotif(n)[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={resolveNotif(n)[1]}
                  secondary={formDate(n.updatedAt)}
                />
                {/*{n.valid ? (
                  <Tooltip title="Aktívna">
                    <Avatar>
                      <ValidIcon />
                    </Avatar>
                  </Tooltip>
                ) : (
                  <Tooltip title="Neaktívna">
                    <Avatar>
                      <InvalidIcon />
                    </Avatar>
                  </Tooltip>
                )}*/}
                <p>{n._id}</p>
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          ))}
        </List>
      </PerfectScrollbar>
    </InfoModal>
  );
};

export default VDNotifModal;
