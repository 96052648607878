import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Modal } from "components";
import { Typography, CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const ProgressWrapper = styled.div`
  width: 100%;
  text-align: center
`;

const ConfirmationModal = (props) => {
  const {
    modalOpen,
    onClose,
    onPositive,
    onNegative,
    title,
    message,
    onOpen,
    isLoading,
    errorOccured
  } = props;

  //const loading = isLoading && typeof isLoading === "boolean" ? isLoading : false;

  useEffect(() => {
    if (modalOpen) {
      onOpen && onOpen();
    }
  }, [modalOpen]);

  const handleClose = () => {
    onClose && onClose();
  };

  const positiveAction = () => {
    onPositive && onPositive();
    handleClose();
  };

  const negativeAction = () => {
    onNegative && onNegative();
    handleClose();
  };

  return (
    <Modal
      title={
        title
        //!isLoading ? title : <CircularProgress color="secondary" size={26} />
      }
      open={modalOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      actions={
        <>
          <Button autoFocus disabled={isLoading || errorOccured} onClick={positiveAction}>
            Áno
          </Button>
          <Button autoFocus disabled={isLoading || errorOccured} onClick={negativeAction}>
            Nie
          </Button>
        </>
      }
    >
      {!isLoading ? (
        <Typography gutterBottom>{message}</Typography>
      ) : (
        <ProgressWrapper>
          <CircularProgress color="secondary" size={26} />
        </ProgressWrapper>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
