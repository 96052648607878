import React, { useState, useEffect } from "react";
import { Link as RouterLink, Redirect, withRouter } from "react-router-dom";
import { useRecoilState } from "recoil";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { snacks } from "helpers";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import AuthImage from "assets/csob-be.jpg";

import { isLoading, loggedUser } from "store";
import { postData } from "fetchers";
import { SIGN_IN_END } from "config";

// TODO: temporary commented
//import app from 'fbase';

const schema = {
  email: {
    presence: {
      allowEmpty: false,
      message: "^Emailová adresa nemôže byť prázdna.",
    },
    email: {
      message: "je nesprávny.",
    },
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: "^Heslo nemôže byť prázdne.",
    },
    length: {
      tooShort: "^Minimálna dĺžka hesla je 6 znakov.",
      tooLong: "^Maximálna dĺžka hesla je 128 znakov.",
      minimum: 6,
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.default,
      height: "calc(100vh - 64px)",
    },
    grid: {
      height: "100%",
    },
    quoteContainer: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    quote: {
      backgroundColor: theme.palette.neutral,
      height: "100%",
      display: "flex",
      paddingTop: "100px",
      justifyContent: "center",
      alignItems: "flex-start",
      backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`, //'url(/images/auth.jpg)',
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    quoteInner: {
      textAlign: "center",
      flexBasis: "600px",
    },
    quoteText: {
      color: theme.palette.white,
      fontWeight: 300,
    },
    name: {
      marginTop: theme.spacing(3),
      color: theme.palette.white,
    },
    bio: {
      color: theme.palette.white,
    },
    contentContainer: {},
    content: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentHeader: {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(5),
      paddingBototm: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    logoImage: {
      marginLeft: theme.spacing(4),
    },
    contentBody: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
    },
    form: {
      paddingLeft: 100,
      paddingRight: 100,
      paddingBottom: 125,
      flexBasis: 700,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    title: {
      margin: theme.spacing(3, 0),
      //marginBottom: theme.spacing(3)
    },
    /*socialButtons: {
        marginTop: theme.spacing(3)
      },
      socialIcon: {
        marginRight: theme.spacing(1)
      },*/
    sugestion: {
      marginTop: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    signInButton: {
      margin: theme.spacing(2, 0),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  };
});

const SignIn = (props) => {
  const { history } = props;

  const classes = useStyles({ backgroundImage: AuthImage });

  const [appIsLoading, setAppIsLoading] = useRecoilState(isLoading);
  const [user, setUser] = useRecoilState(loggedUser);

  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  /*const handleBack = () => {
      history.goBack();
    };*/

  const handleChange = (event) => {
    event.persist();


    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      setAppIsLoading(true);
      const res = await postData(SIGN_IN_END, {
        email: formState.values.email,
        password: formState.values.password,
      });

      setUser(res);

      // TODO: temporary commented
      //await app.auth().signInWithCustomToken(res.customToken)
      history.push("/");
    } catch (err) {
      switch (err.status) {
        case 404:
          enqueueSnackbar(
            "Používateľ so zadaným emailom neexistuje",
            snacks.ERROR
          );
          break;
        case 401:
          enqueueSnackbar("Zadali ste nesprávne heslo", snacks.ERROR);
          break;
        default:
          break;
      }
    } finally {
      setAppIsLoading(false);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return user && user.token ? (
    <Redirect to="/" />
  ) : (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  ČSOBL Dochádzkový systém
              </Typography>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                {/*<IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>*/}
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSignIn}>
                  <Typography className={classes.title} variant="h2">
                    Prihlásenie
                </Typography>
                  <Grid
                    className={classes.socialButtons}
                    container
                    spacing={2}
                  ></Grid>
                  {/* <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  prihlásiť sa prostredníctvom emailu
                </Typography> */}
                  <TextField
                    className={classes.textField}
                    error={hasError("email")}
                    fullWidth
                    helperText={
                      hasError("email") ? formState.errors.email[0] : null
                    }
                    label="Emailová adresa"
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ""}
                    variant="outlined"
                    autoComplete="off"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError("password")}
                    fullWidth
                    helperText={
                      hasError("password") ? formState.errors.password[0] : null
                    }
                    label="Heslo"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ""}
                    variant="outlined"
                    autoComplete="off"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {!appIsLoading ? (
                      "Prihlásiť"
                    ) : (
                        <CircularProgress color="secondary" size={26} />
                      )}
                  </Button>
                  <Typography color="textSecondary" variant="body1">
                    {/*Don't have an account?{' '}*/}
                    <Link component={RouterLink} to="/lost-password" variant="h6">
                      Zabudnuté heslo
                  </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignIn);
