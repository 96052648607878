import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { loggedUser } from "store";
import axios from "axios";

export const useAuth = () => {
  const user = useRecoilValue(loggedUser);

  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (user) {
      const bearer = user.token;
      axios.defaults.headers.common = { Authorization: `Bearer ${bearer}` };
      setInitialised(true);
    }
    setInitialised(true);
  }, [user]);

  return { user, initialised };
};


const Authenticator = (props) => {
  const { user, initialised } = useAuth();

  if (!initialised) {
    return null;
  } else {
    return <>{props.children}</>;
  }
};

export default Authenticator;

/*
// Add a 401 response interceptor
window.axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
      // handle error: inform user, go to login, etc
  } else {
      return Promise.reject(error);
  }
});
*/
