import React from "react";

const VDInfo = (props) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      <p style={{ textAlign: "justify" }}>
      Vítame vás v aplikácii ČSOBL Dochádzka. Táto aplikácia slúži na zaznamenávanie (zapisovanie) a potvrdzovanie dochádzky pre dohodárov. Prosíme, skontrolujte si dátumy uvedené v kalendári. Uistite sa, že ste v daný dátum neboli PN alebo na OČR. Pokiaľ je dochádzka v poriadku, potvrďte ju príslušným tlačidlom. Pokiaľ potrebujete dochádzku zmeniť, môžete tak urobiť chytením a premiestnením políčka s číslom zmluvy. Potvrdená dochádzka sa nedá zmeniť. Dochádzka sa potvrdzuje stále za predchádzajúci mesiac. Ak ste v minulom mesiaci nepotvrdili dochádzku, zmluvy sa vám automaticky preniesli do aktuálnej dochádzky.
     </p>
    </div>
  );
};

export default VDInfo;
