import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
import { loggedUser } from "store";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
    fontSize: '1em',
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const user = useRecoilValue(loggedUser);
  /*j
  const user = {
    email: 'cosbladmin@csobl.sk'
  }
  const [profileData, setProfileData] = useState({
    bio: 'Admin'
  })*/

  /*const user = {
    name: 'John Doe',
    //avatar: '/images/avatars/avatar_11.png',
    bio: 'IFF Researcher'
  };*/

  const getInitials = (name) => {
    const parts = name.split(" ");
    return parts[0][0] + parts[1][0];
  };

  const capitalise = (string) => {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt={user && user.email}
        className={classes.avatar}
        component={RouterLink}
        src={user && user.avatar}
        to="/"
      >
        ČSOBL
        {/*{getInitials(profileData.name)}*/}
      </Avatar>
      <Typography className={classes.name} variant="h6">
        {user && user.name}
      </Typography>
      <Typography variant="body2">{capitalise(user && user.role)}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
