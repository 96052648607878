import React from 'react';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import VendorsIcon from '@material-ui/icons/AccountBox';
//import ScheduleIcon from '@material-ui/icons/Schedule';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


import { Profile, SidebarNav } from './components';

import { confirmationModal } from 'store';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const [openSignOut, setOpenSignOut] = useRecoilState(confirmationModal);


  const items = [
    /*{
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },*/
    {
      title: 'Dohodári',
      href: '/vendors',
      icon: <VendorsIcon />,
      onClick: () => {
        onClose();

      }
    },
    {
      title: 'Odhlásiť sa',
      //href: '/sign-in',
      icon: <ExitToAppIcon />,
      logOut: true,
      onClick: () => {
        setOpenSignOut(true)
        //onClose();
      },
    },
    /*{
      title: 'Help',
      //href: '/sign-in',
      icon: <HelpOutlineIcon />,
      bottom: true,
      onClick: () => {
        //onClose();
      },
    }*/
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          items={items}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
