import palette from '../palette';

export default {
  root: {
    "&$focused $notchedOutline": {
      borderColor: `${palette.primary.contrastText}`
    }
  },
  focused: {},
  notchedOutline: {}
}




